import { AgoraClient } from "../../components/AgoraComponent";
import { store } from "../../store";
import { setAudioFileTrack } from "../redux/Agora/agoraSlice";

const pauseSingleAudioFile = async ({ audioFileTrack, index, pauseCallback, resumeCallback }) => {
  if(!audioFileTrack) return;

  const dispatch = store.dispatch;
  if (!dispatch) return;

  if (audioFileTrack?.isPlaying) {
    audioFileTrack.stop();
    audioFileTrack.pauseProcessAudioBuffer();
    await AgoraClient.unpublish(audioFileTrack);
    pauseCallback && pauseCallback(audioFileTrack);
  } else {
    audioFileTrack?.play();
    audioFileTrack.resumeProcessAudioBuffer();
    await AgoraClient.publish(audioFileTrack);
    resumeCallback && resumeCallback(audioFileTrack);
  }

  dispatch(setAudioFileTrack(audioFileTrack));
};

export default pauseSingleAudioFile;
