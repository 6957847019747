import { useContext, useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import Card from "../../components/Card";
import PlayerButton from "../../components/PlayerButton";
import CongratulationsModal from "../../components/CongratulationsModal";
import Confetti from "react-confetti";
import { AppContext } from "../../context/AppContext";
import Note from "./components/Note";
import NOTES from "../../utils/constants/NOTES";
import { SignalRContext } from "../../context/SignalRContext";
import GameDescriptionModal from "../../components/GameDescriptionModal";

import RemoveButton from "../../assets/icons/remove-button.svg";
import RECORD_PARTS from "../../utils/constants/RECORD_PARTS";
import { useTranslate } from "../../utils/hooks/useTranslate";
import playSingleAudioFile from "../../utils/helpers/playSingleAudioFile";
import playMultipleAudioFiles from "../../utils/helpers/playMultipleAudioFiles";
import pauseSingleAudioFile from "../../utils/helpers/pauseSingleAudioFile";
import seekAudioFile from "../../utils/helpers/seekAudioFile";
import pauseMultipleAudioFiles from "../../utils/helpers/pauseMultipleAudioFiles";

const Virtuoso = () => {
  const [notes, setNotes] = useState([]);
  const [onPlayerNotes, setOnPlayerNotes] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isPlayingSong, setIsPlayingSong] = useState(false);
  const [isPausedSong, setIsPausedSong] = useState(false);
  const [fullTrackCheckingProgress, setFullTrackCheckingProgress] = useState(0);
  const [fullTrackDuration, setFullTrackDuration] = useState(0);
  const [fullTrackAudioFile, setFullTrackAudioFile] = useState(null);

  const [hasEmptyNote, setHasEmptyNote] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [_, setOnPlayerTrackCheckingProgress] = useState(0);
  const [currentCheckedTrackIndex, setCurrentCheckedTrackIndex] =
    useState(null);
  const [startFromNote, setStartFromNote] = useState(0);

  const { app, roomState, gameState, updateGameState, updateRoomState } = useContext(AppContext);
  const { checkTrackOrder, updateOnPlayerNotes, startPlaying, stopPlaying } =
    useContext(SignalRContext);

  const [audioFileTrack, setAudioFileTrack] = useState(null);
  const [audioFileTracks, setAudioFileTracks] = useState([]);
  const [audioFileTrackDuration] = useState(0);

  const { translate } = useTranslate();

  const progressRange = useRef(null);

  useEffect(() => {
    if (gameState?.player?.role !== "virtuoso")
      updateGameState({ player: { ...gameState.player, role: "virtuoso" } });
  }, [gameState.player, updateGameState]);

  useEffect(() => {
    if (isPlaying) handlePausePlayCheckingTracks();
    if (isPlayingSong) handlePauseFullTrack();

    setIsPlaying(false);
    setIsPaused(false);
    setIsPlayingSong(false);
    setIsPausedSong(false);
    setFullTrackCheckingProgress(0);
    setFullTrackDuration(0);
    setFullTrackAudioFile(null);
    setOnPlayerTrackCheckingProgress(0);
    setCurrentCheckedTrackIndex(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameState?.page]);

  useEffect(() => {
    let table = [];
    roomState?.records
      ?.sort((a, b) => a?.originalOrder - b?.originalOrder)
      ?.forEach((note) => {
        table.push({
          "Original Order": note?.originalOrder,
          "Virtuoso Order": note?.order + 1,
        });
      });

    console.table(table);
  }, [roomState?.records]);

  useEffect(() => {
    if (!roomState?.records?.length) return;

    const notes = roomState?.records?.map((record) => {
      return {
        ...record,
        onPlayer: false,
      };
    });

    setNotes(notes);
    setOnPlayerNotes(Array.from({ length: notes.length }, () => false));
  }, [roomState?.records]);

  useEffect(() => {
    updateOnPlayerNotes(
      onPlayerNotes.filter((note) => note).map((note) => note.partId),
    );

    if (onPlayerNotes.filter((note) => note).length === 0) return;

    setHasEmptyNote(false);

    if (!onPlayerNotes[0]) setHasEmptyNote(1);

    onPlayerNotes.some((note, index) => {
      if (!note) {
        setHasEmptyNote(index);
        return true;
      }

      return false;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onPlayerNotes]);

  useEffect(() => {
    let interval = "";
    if (isPlayingSong) {
      if (isPausedSong) clearInterval(interval);

      interval = setInterval(() => {
        setFullTrackCheckingProgress(
          (fullTrackCheckingProgress) => fullTrackCheckingProgress + 0.1,
        );
      }, 100);
    }

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlayingSong, isPausedSong]);

  useEffect(() => {
    let interval = "";

    if (isPlaying && !isPaused) {
      interval = setInterval(() => {
        setOnPlayerTrackCheckingProgress(
          (onPlayerTrackCheckingProgress) => onPlayerTrackCheckingProgress + 0.1,
        );
      }, 100);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying, isPaused]);

  useEffect(() => {
    if (audioFileTracks?.length) postHandlePlayAllTracks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioFileTracks]);

  useEffect(() => {
    if (audioFileTrackDuration) {
      setFullTrackDuration(Math.floor(audioFileTrackDuration));
    }
  }, [audioFileTrackDuration]);

  const handleDragStart = (e, note) => {
    if (note.onPlayer) return;

    e.dataTransfer.setData("note", JSON.stringify(note));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    if (isPlaying) {
      if (!isPaused) handlePausePlayCheckingTracks();
      handleEndCheckingTracks();
    }

    const { index } = e.target.dataset;

    let note = e.dataTransfer.getData("note");

    if (!note) return;

    note = JSON.parse(note);

    addNoteToPlayer(note, index);
  };

  const addNoteToPlayer = (note, index) => {
    if (isPlaying) {
      if (!isPaused) handlePausePlayCheckingTracks();
      handleEndCheckingTracks();
    }

    if (onPlayerNotes?.find((n) => n?.partId === note?.partId)) return;

    const newOnPlayerNotes = [...onPlayerNotes];

    let newIndex = index;

    if (!newIndex) {
      const firstEmptyNoteIndex = newOnPlayerNotes.findIndex((note) => !note);

      if (firstEmptyNoteIndex === -1) return;

      newIndex = firstEmptyNoteIndex;
    }

    newOnPlayerNotes[newIndex] = note;

    const newNotes = [...notes];

    const noteIndex = newNotes.findIndex((n) => n?.partId === note?.partId);
    newNotes[noteIndex].onPlayer = true;

    if (onPlayerNotes[newIndex]) {
      const oldNoteIndex = notes.findIndex(
        (n) => n?.partId === onPlayerNotes[newIndex]?.partId,
      );

      newNotes[oldNoteIndex].onPlayer = false;
    }

    setOnPlayerNotes(newOnPlayerNotes);
    setNotes(newNotes);
  };

  const removeNoteFromPlayer = (index) => {
    if (isPlaying) {
      handlePausePlayCheckingTracks();
      handleEndCheckingTracks();
    }

    let newOnPlayerNotes = [...onPlayerNotes];

    newOnPlayerNotes[index] = false;

    setOnPlayerNotes(newOnPlayerNotes);

    const newNotes = [...notes];

    const noteIndex = newNotes.findIndex(
      (n) => n?.partId === onPlayerNotes[index]?.partId,
    );
    newNotes[noteIndex].onPlayer = false;

    setNotes(newNotes);

    updateRoomState({
      listCorrectOrWrong: [],
    });
  };

  const handleCheckTracks = (playFromNote = 0, checkOrder = false) => {
    if (
      (isPlaying && !isPaused) ||
      (isPlayingSong && !isPausedSong) ||
      onPlayerNotes.filter((note) => note)?.length < 1
    )
      return;

    if (
      onPlayerNotes.filter((note) => note)?.length ===
      roomState?.records?.length &&
      checkOrder
    ) {
      handleEndFullTrack();
      checkTrackOrder(onPlayerNotes?.map((n) => n.partId));
    } else {
      if (onPlayerNotes[playFromNote]?.partId === null) return;

      const notesFromMiddle = onPlayerNotes?.slice(playFromNote);

      const emptyIndex = notesFromMiddle.findIndex((note) => !note);

      const tracks = playFromNote
        ? notesFromMiddle?.slice(0, emptyIndex > -1 ? emptyIndex : undefined)
        : onPlayerNotes?.slice(
          playFromNote || 0,
          hasEmptyNote
            ? hasEmptyNote
            : onPlayerNotes?.filter((note) => note)?.length,
        );

      setStartFromNote(playFromNote);

      playMultipleAudioFiles({
        tracks: tracks?.map((note) => note?.recordUrl),
        onTracksEnd: handleEndCheckingTracks,
        setCurrentIndex: setCurrentCheckedTrackIndex,
        offset: playFromNote,
        updateGameState,
        gameState,
        callback: (audioFileTracks) => {
          setAudioFileTracks(audioFileTracks);
          setIsPlaying(true);
          setIsPaused(false);
          startPlaying();
        },
      });
    }
  };

  const postHandlePlayAllTracks = () => {
    if (audioFileTracks?.length < 1) return;

    setIsPlaying(true);
    setIsPaused(false);
  };

  const handlePausePlayCheckingTracks = () => {
    pauseMultipleAudioFiles({
      audioFileTracks,
      pause: !isPaused,
      index: currentCheckedTrackIndex - startFromNote,
      callback: () => {
        if (isPaused) startPlaying();
        else stopPlaying();

        setIsPaused(!isPaused);
      },
    });
  };

  const handleEndCheckingTracks = () => {
    setIsPlaying(false);
    setIsPaused(false);

    stopPlaying();

    setOnPlayerTrackCheckingProgress(0);
    setFullTrackCheckingProgress(0);

    const pitchArea = document.querySelector(".pitch-area");

    if (pitchArea) {
      pitchArea.classList.add("ended");

      setTimeout(() => {
        setCurrentCheckedTrackIndex(null);
      }, 210);

      setTimeout(() => {
        pitchArea.classList.remove("ended");
      }, 300);
    } else {
      setCurrentCheckedTrackIndex(null);
    }
  };

  const handlePlayFullTrack = (gameOver = false) => {
    if ((isPlaying && !isPaused) || (isPlayingSong && !isPausedSong)) return;

    playSingleAudioFile({
      track: roomState?.fullRecordUrl,
      updateGameState,
      gameState,
      gameOver,
      onTrackEnd: handleEndFullTrack,
      callback: (audioFileTrack) => {
        setAudioFileTrack(audioFileTrack);
        setIsPlayingSong(true);

        startPlaying();
        if (!gameOver) {
          setFullTrackDuration(Math.floor(audioFileTrack?.duration));
          setFullTrackAudioFile(audioFileTrack);
        }
      },
    });
  };

  const handlePauseFullTrack = () => {
    if (isPlayingSong) {
      pauseSingleAudioFile({
        audioFileTrack,
        pauseCallback: () => {
          setIsPausedSong(true);
          stopPlaying();
        },
        resumeCallback: () => {
          setIsPausedSong(false);
          startPlaying();
        },
      });
    }
  };

  const handleEndFullTrack = () => {
    setIsPlayingSong(false);
    setIsPausedSong(false);

    stopPlaying();

    setFullTrackDuration(0);
    setFullTrackAudioFile(null);
  };

  const handleProgressRangeChange = (e) => {
    if (isPlayingSong) {
      seekAudioFile({
        audioFileTrack: fullTrackAudioFile,
        time: (e.target.value / 100) * fullTrackDuration,
      });
    }
  };

  return (
    <div
      className="virtuoso container-fluid main d-flex flex-column align-items-center justify-content-between"
      style={{
        backgroundImage: `url(${
          RECORD_PARTS[gameState?.player?.box - 1]?.box || RECORD_PARTS[0]?.box
        })`,
      }}
    >
      <Header/>
      <div className="row d-flex align-items-center justify-content-center">
        <div className="col-10 col-lg-9">
          <Card className="d-flex flex-column align-items-center overflow-auto">
            <div className="notes-holder d-flex flex-row justify-content-center align-items-center w-100 h-100">
              {notes
                ?.sort((a, b) => a.order - b.order)
                .map((note, index) => (
                  <Note
                    key={index}
                    note={note}
                    isAdded={note?.onPlayer}
                    onClick={() => {
                      if (note?.onPlayer) return;
                      addNoteToPlayer(note);
                    }}
                    draggable={!note?.onPlayer}
                    onDragStart={(e) =>
                      !note?.onPlayer
                        ? handleDragStart(e, note)
                        : e.preventDefault()
                    }
                  />
                ))}
            </div>
            <div
              className="pitch-area position-relative player-bg d-flex flex-row align-items-center justify-content-center my-3">
              {onPlayerNotes.map((playerNote, i) => (
                <div
                  className={`pitch d-flex flex-column align-items-center justify-content-between cursor-pointer position-relative py-3 ${
                    playerNote?.partId !== null ? "pitch-blue" : ""
                  }`}
                  onDragOver={(e) => handleDragOver(e)}
                  onDrop={(e) => handleDrop(e)}
                  key={i}
                  data-index={i}
                  onClick={() => {
                    if (playerNote) {
                      handleCheckTracks(i);
                    }
                  }}
                  data-is-correct={roomState?.listCorrectOrWrong?.length ? String(roomState?.listCorrectOrWrong?.[i]) === "true" : false}
                  data-is-wrong={roomState?.listCorrectOrWrong?.length ? String(roomState?.listCorrectOrWrong?.[i]) === "false" : false}
                >
                  <div
                    className="single-track-progress-bar position-absolute w-100 h-100"
                    data-progress={
                      !playerNote?.partId || i < startFromNote
                        ? 0
                        : currentCheckedTrackIndex > i
                          ? 100
                          : currentCheckedTrackIndex === i
                            ? Math.floor(
                              (audioFileTracks[
                                currentCheckedTrackIndex - startFromNote
                                  ]?.getCurrentTime() /
                                audioFileTracks[
                                currentCheckedTrackIndex - startFromNote
                                  ]?.duration) *
                              100,
                            )
                            : 0
                    }
                  ></div>
                  {playerNote?.iconId && (
                    <img
                      src={NOTES[playerNote?.iconId]}
                      alt="note"
                      className="note-icon flex-shrink-0"
                    />
                  )}
                  {playerNote && (
                    <div
                      className="remove-button d-flex align-items-center justify-content-center"
                      role="button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        removeNoteFromPlayer(i);
                      }}
                    >
                      <img src={RemoveButton} alt="Remove icon"/>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="col-10 d-flex align-items-center my-3">
              <div
                className="play-song-button d-flex flex-shrink-0 align-items-center justify-content-center text-white">
                <PlayerButton
                  isPlaying={isPlayingSong && !isPausedSong}
                  onClick={
                    isPlayingSong
                      ? handlePauseFullTrack
                      : () => handlePlayFullTrack(false)
                  }
                  customText={
                    isPlayingSong
                      ? isPausedSong
                        ? translate(`CONTINUE`)
                        : translate(`PAUSE`)
                      : translate(`LISTEN_MUSIC`)
                  }
                  disabled={isPlaying && !isPaused}
                />
              </div>

              <div className="progress mx-3 position-relative">
                <input
                  ref={progressRange}
                  type="range"
                  className="w-100 h-100 position-absolute cursor-pointer"
                  value={
                    fullTrackAudioFile
                      ? Math.floor(
                        (fullTrackAudioFile?.getCurrentTime() /
                          fullTrackAudioFile?.duration) *
                        100,
                      )
                      : 0
                  }
                  onChange={handleProgressRangeChange}
                />
                <div
                  className="overlay position-absolute w-100 h-100"
                  role="button"
                ></div>
                <div
                  className="progress-bar col-12"
                  role="progressbar"
                  aria-valuenow={Math.floor(fullTrackCheckingProgress)}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  data-progress={
                    fullTrackAudioFile
                      ? Math.floor(
                        (fullTrackAudioFile?.getCurrentTime() /
                          fullTrackAudioFile?.duration) *
                        100,
                      )
                      : 0
                  }
                ></div>
                <div
                  className="progress-ball position-absolute"
                  role="button"
                  style={{
                    left: `${
                      fullTrackAudioFile
                        ? Math.floor(
                          (fullTrackAudioFile?.getCurrentTime() /
                            fullTrackAudioFile?.duration) *
                          100,
                        )
                        : 0
                    }%`,
                  }}
                ></div>
              </div>
              {Boolean(fullTrackDuration) && (
                <p className="progress-text text-white mb-0">
                  00:
                  {fullTrackAudioFile?.getCurrentTime() < 10
                    ? "0" + Math.floor(fullTrackAudioFile?.getCurrentTime())
                    : Math.floor(fullTrackAudioFile?.getCurrentTime())}
                  /00:{Math.floor(fullTrackAudioFile?.duration)}
                </p>
              )}
            </div>
            <div className="play-button-area d-flex flex-column align-items-center justify-content-center py-2">
              <PlayerButton
                customText={
                  isPlaying
                    ? isPaused
                      ? translate(`CONTINUE`)
                      : translate(`PAUSE`)
                    : onPlayerNotes.filter((note) => note).length ===
                    notes.length
                      ? translate(`CHECK_PART_ORDER`)
                      : translate(`LISTEN_PARTS`)
                }
                disabled={
                  onPlayerNotes.filter((note) => note).length < 1 ||
                  (isPlayingSong && !isPausedSong)
                }
                onClick={() =>
                  isPlaying
                    ? handlePausePlayCheckingTracks()
                    : handleCheckTracks(
                      onPlayerNotes?.findIndex((note) => note),
                      true,
                    )
                }
              />
            </div>
          </Card>
        </div>
      </div>
      <div className="footer-placeholder">&nbsp;</div>

      <GameDescriptionModal type="VIRTUOSO"/>

      <CongratulationsModal
        show={app?.modal?.modalType === "TrackSuccess"}
        roundPoint={app?.modal?.data?.trackPoint}
        gamePoint={app?.modal?.data?.gamePoint}
        artistName={app?.modal?.data?.artistName}
        trackName={app?.modal?.data?.trackName}
        cover={app?.modal?.data?.artistImageUrl}
      />

      {app?.modal?.modalType === "TrackSuccess" && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={200}
          recycle={app?.modal?.modalType === "TrackSuccess"}
          gravity={0.1}
        />
      )}
    </div>
  );
};

export default Virtuoso;
