import AgoraRTC from "agora-rtc-sdk-ng";
import { delay, put, select, takeLatest } from "redux-saga/effects";
import {
  connectDevices,
  connectDevicesFail,
  connectDevicesSuccess,
  connectToPlayer,
  devicesRequest,
  devicesRequestSuccess,
  init,
  initSuccess,
  joinRoom,
  joinRoomSuccess,
  leaveRoom,
  leaveRoomSuccess,
  mutePlayer,
  mutePlayerSuccess,
  pausePlayMultipleAudioFiles,
  pausePlaySingleAudioFile,
  playMultipleAudioFiles,
  playMultipleAudioFilesEnd,
  playMultipleAudioFilesStart,
  playSingleAudioFile,
  playSingleAudioFileStart,
  resetPlayerAudioFix,
  seekSingleAudioFile,
  setInputDevice,
  setInputDeviceSuccess,
  setOutputDevice,
  setShowMutedInfo,
  stopSingleAudioFile,
  stopSingleAudioFileSuccess,
  unmutePlayer,
  unmutePlayerSuccess,
} from "./agoraSlice";

import toastr from "toastr";

const showToastr =
  window.location.hostname.includes("localhost") ||
  window.location.hostname.includes("webstage");

toastr.options = {
  newestOnTop: true,
  positionClass: "toast-bottom-left",
};

function* postJoin(action) {
  try {
    const { client, localAudioTrack } = yield select((state) => state.agora);

    yield client.publish(localAudioTrack);

    showToastr && toastr.info("Your audio is being published");
    delay(50);

    if (!action.payload.unmuted) {
      yield put(mutePlayer());
      yield put(setShowMutedInfo(true));
    } else yield put(unmutePlayer());
  } catch (error) {
    console.error(error);
  }
}

function* initSaga() {
  try {
    AgoraRTC.enableLogUpload();
    AgoraRTC.setLogLevel(1);

    const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
    yield put(initSuccess(client));
  } catch (error) {
    console.error(error);
  }
}

function* joinSaga(action) {
  try {
    const { client, localAudioTrack } = yield select((state) => state.agora);

    if (client?.connectionState !== "DISCONNECTED" || !action.payload) return;

    yield client.join(
      action.payload.appId,
      action.payload.channel,
      action.payload.token,
      action.payload.uid
    );

    if (localAudioTrack?.enabled) {
      yield postJoin(action);
    } else yield put(connectDevices(action.payload));

    yield put(joinRoomSuccess(action.payload));
  } catch (error) {
    console.error(error);
  }
}

function* leaveSaga() {
  try {
    const { localAudioTrack, remoteAudioTrack, client } = yield select(
      (state) => state.agora
    );

    localAudioTrack?.stop();
    remoteAudioTrack?.stop();

    yield client.leave();

    yield put(leaveRoomSuccess());
  } catch (error) {
    console.error(error);
  }
}

function* connectToPlayerSaga(action) {
  try {
    const { user, mediaType } = action?.payload;
    if (!user || !mediaType) return false;

    const { client } = yield select((state) => state.agora);
    yield client.subscribe(action.payload.user, action.payload.mediaType);

    console.log("subscribe success", user?.uid);
    showToastr && toastr.info("subscribe success", user?.uid);
    if (mediaType === "audio") {
      user?.audioTrack?.play();
    }
  } catch (error) {
    console.error(error);
  }
}

function* devicesRequestSaga() {
  try {
    const inputDevices = yield AgoraRTC.getMicrophones();
    const outputDevices = yield AgoraRTC.getPlaybackDevices();

    const input = [];
    const output = [];

    inputDevices.forEach((device) => {
      const option = { value: "", label: "" };

      option.value = device.deviceId;
      option.label = device.label || `microphone ${input.length + 1}`;
      option.isDefault = device.deviceId === "default";
      input.push(option);
    });

    outputDevices.forEach((device) => {
      const option = { value: "", label: "" };

      option.value = device.deviceId;
      option.label = device.label || `speaker ${output.length + 1}`;
      option.isDefault = device.deviceId === "default";
      output.push(option);
    });

    yield put(devicesRequestSuccess({ input, output }));
  } catch (error) {
    console.error(error);
  }
}

function* connectDevicesSaga(action) {
  try {
    const { client } = yield select((state) => state.agora);
    const configs = {};

    if (action?.payload?.microphoneId) {
      configs.microphoneId = action.payload.microphoneId;
    }

    const localAudioTrack = yield AgoraRTC.createMicrophoneAudioTrack(configs);

    if (client?.channelName) {
      yield postJoin(action);
    }

    yield put(connectDevicesSuccess(localAudioTrack));
  } catch (error) {
    console.error(error);
    yield put(connectDevicesFail());
  }
}

function* setInputDeviceSaga(action) {
  try {
    const { localAudioTrack } = yield select((state) => state.agora);
    localAudioTrack?.setDevice(action.payload);

    yield put(setInputDeviceSuccess(action.payload));
  } catch (error) {
    console.error(error);
  }
}

function* setOutputDeviceSaga(action) {
  try {
    const { localAudioTrack } = yield select((state) => state.agora);
    localAudioTrack.setPlaybackDevice(action.payload);
  } catch (error) {
    console.error(error);
  }
}

function* mutePlayerSaga() {
  try {
    const { localAudioTrack } = yield select((state) => state.agora);
    localAudioTrack?.setMuted(true);
    console.log("Player muted");
    showToastr && toastr.info("You are muted");
    yield put(mutePlayerSuccess());
  } catch (error) {
    console.error(error);
  }
}

function* unmutePlayerSaga() {
  try {
    const { localAudioTrack, showMutedInfo } = yield select(
      (state) => state.agora
    );
    localAudioTrack?.setMuted(false);
    console.log("Player unmuted");
    showToastr && toastr.info("You are unmuted");
    yield put(unmutePlayerSuccess());

    if (showMutedInfo) yield put(setShowMutedInfo(false));
  } catch (error) {
    console.error(error);
  }
}

function* resetPlayerAudioFixSaga() {
  try {
    const { localAudioTrack } = yield select((state) => state.agora);

    if (!localAudioTrack) return;

    const currentStatus = localAudioTrack.muted;
    if (!currentStatus) yield put(mutePlayer());

    yield delay(50);

    if (!currentStatus) yield put(unmutePlayer());
  } catch (error) {
    console.error(error);
  }
}

function* playSingleAudioFileSaga(action) {
  let newAudioFileTrack = null;
  const { audioFileTrack, client } = yield select((state) => state.agora);
  try {
    // If currently playing, clean up first
    if (audioFileTrack?.isPlaying) {
      try {
        audioFileTrack.stopProcessAudioBuffer();
        yield client.unpublish(audioFileTrack);
        audioFileTrack.off("source-state-change");
      } catch (cleanupError) {
        console.warn('Cleanup error:', cleanupError);
      }
    }

    // Create new track with proper error handling
    try {
      newAudioFileTrack = yield AgoraRTC.createBufferSourceAudioTrack({
        source: action.payload.track,
      });
    } catch (trackError) {
      // Specific handling for CORS/network errors
      if (trackError.message.includes('CORS') || trackError.message.includes('network')) {
        // Could implement retry logic or fallback here
        console.error(`Failed to load audio track: ${trackError.message}`);
        return;
      }
      console.error(trackError);
      return;
    }

    // Set volume
    const volume = action.payload.gameOver ? 4 : 10;
    newAudioFileTrack?.setVolume(volume);

    // Start processing with error handling
    try {
      newAudioFileTrack.startProcessAudioBuffer({
        startPlayTime: 0,
        loop: action.payload?.loop,
      });
    } catch (processError) {
      console.error(`Failed to process audio: ${processError.message}`);
      return;
    }

    // Publish and play with proper error handling
    try {
      yield client.publish(newAudioFileTrack);
      yield newAudioFileTrack.play();
    } catch (publishError) {
      console.error(`Failed to publish/play track: ${publishError.message}`);
      return;
    }

    // Update game state
    if (action.payload.updateGameState) {
      action.payload.updateGameState({
        player: { ...action.payload.gameState?.player, isPlayingTrack: true },
      });
    }

    // Handle track state changes
    newAudioFileTrack.on("source-state-change", async (state) => {
      if (state === "stopped") {
        try {
          if (action.payload.updateGameState) {
            action.payload.updateGameState({
              player: {
                ...action.payload.gameState?.player,
                isPlayingTrack: false,
              },
            });
          }

          newAudioFileTrack.stopProcessAudioBuffer();
          await client.unpublish(newAudioFileTrack);
          newAudioFileTrack.off("source-state-change");

          action.payload.onEnd?.();
          if (!action.payload.gameOver) {
            action.payload.onTrackEnd?.();
          }
        } catch (cleanupError) {
          console.error('Error during track cleanup:', cleanupError);
        }
      }
    });

    action.payload.callback?.(newAudioFileTrack);
    yield put(playSingleAudioFileStart(newAudioFileTrack));

  } catch (error) {
    // Proper error handling
    console.error('Audio playback error:', error);

    // Cleanup on error
    if (newAudioFileTrack) {
      try {
        newAudioFileTrack.stopProcessAudioBuffer();
        yield client.unpublish(newAudioFileTrack);
        newAudioFileTrack.off("source-state-change");
      } catch (cleanupError) {
        console.warn('Cleanup error during error handling:', cleanupError);
      }
    }

    // Update game state on error
    if (action.payload.updateGameState) {
      action.payload.updateGameState({
        player: { ...action.payload.gameState?.player, isPlayingTrack: false },
      });
    }

    console.error(error);
  }
}

function* stopSingleAudioFileSaga() {
  try {
    const { audioFileTrack, client } = yield select((state) => state.agora);

    if (audioFileTrack?.isPlaying) {
      audioFileTrack.stopProcessAudioBuffer();
      yield client.unpublish(audioFileTrack);
      audioFileTrack?.stop();
      yield put(stopSingleAudioFileSuccess());
    }
  } catch (error) {
    console.error(error);
  }
}

function* playMultipleAudioFilesSaga(action) {
  try {
    const { audioFileTracks, client } = yield select((state) => state.agora);

    if (audioFileTracks?.length) yield put(playMultipleAudioFilesEnd());

    const newAudioFileTracks = yield Promise.all(
      action.payload.tracks.map(async (track) => {
        const audioFileTrack = await AgoraRTC.createBufferSourceAudioTrack({
          source: track,
        });

        audioFileTrack?.setVolume(10);

        return audioFileTrack;
      })
    );

    if (newAudioFileTracks.length === 0) return;

    // Start processing the audio data from the audio file.
    newAudioFileTracks[0].startProcessAudioBuffer({ startPlayTime: 0 });

    // Publish audioFileTrack and localAudioTrack together.
    yield client.publish(newAudioFileTracks[0]);

    newAudioFileTracks[0].play();

    if (action.payload.setCurrentIndex)
      action.payload.setCurrentIndex(0 + action.payload.offset);

    action.payload.updateGameState &&
      action.payload.updateGameState({
        player: { ...action.payload.gameState?.player, isPlayingTrack: true },
      });

    const allAudioFileTracks = newAudioFileTracks.map(
      (audioFileTrack, index) => {
        audioFileTrack.on("source-state-change", async (state) => {
          if (state === "stopped") {
            audioFileTrack.stopProcessAudioBuffer();
            audioFileTrack.off("source-state-change");

            if (index < newAudioFileTracks.length - 1) {
              setTimeout(async () => {
                newAudioFileTracks[index + 1].startProcessAudioBuffer({
                  startPlayTime: 0,
                });

                await client.publish(newAudioFileTracks[index + 1]);

                newAudioFileTracks[index + 1].play();

                if (action.payload.setCurrentIndex)
                  action.payload.setCurrentIndex(
                    index + 1 + action.payload.offset
                  );
              }, 1);
            } else {
              action.payload.updateGameState &&
                action.payload.updateGameState({
                  player: {
                    ...action.payload.gameState?.player,
                    isPlayingTrack: false,
                  },
                });

              if (action.payload.onTracksEnd) action.payload.onTracksEnd();

              action.payload.onEnd && action.payload.onEnd();
            }
          }
        });

        return audioFileTrack;
      }
    );

    yield put(playMultipleAudioFilesStart(allAudioFileTracks));
  } catch (error) {
    console.error(error);
  }
}

function* pausePlayMultipleAudioFilesSaga(action) {
  try {
    const { client, audioFileTracks } = yield select((state) => state.agora);
    const { index, pause, callback } = action.payload;
    if (pause) {
      audioFileTracks[index].pauseProcessAudioBuffer();
      audioFileTracks[index].stop();
      yield client.unpublish(audioFileTracks[index]);
    } else {
      audioFileTracks[index].play();
      audioFileTracks[index].resumeProcessAudioBuffer();
      yield client.publish(audioFileTracks[index]);
    }

    callback && callback();
  } catch (error) {
    console.error(error);
  }
}

function* pausePlaySingleAudioFileSaga(action) {
  try {
    const { audioFileTrack, client } = yield select((state) => state.agora);
    const { callback } = action.payload;
    if (audioFileTrack?.isPlaying) {
      audioFileTrack.stop();
      audioFileTrack.pauseProcessAudioBuffer();
      yield client.unpublish(audioFileTrack);
    } else {
      audioFileTrack.play();
      audioFileTrack.resumeProcessAudioBuffer();
      yield client.publish(audioFileTrack);
    }

    callback && callback(audioFileTrack);
  } catch (error) {
    console.error(error);
  }
}

function* seekSingleAudioFileSaga(action) {
  try {
    const { audioFileTrack } = yield select((state) => state.agora);

    audioFileTrack?.seekAudioBuffer(action.payload);
  } catch (error) {
    console.error(error);
  }
}

export function* agoraModuleSaga() {
  yield takeLatest(init.type, initSaga);
  yield takeLatest(joinRoom.type, joinSaga);
  yield takeLatest(connectToPlayer.type, connectToPlayerSaga);
  yield takeLatest(devicesRequest.type, devicesRequestSaga);
  yield takeLatest(connectDevices.type, connectDevicesSaga);
  yield takeLatest(setInputDevice.type, setInputDeviceSaga);
  yield takeLatest(setOutputDevice.type, setOutputDeviceSaga);
  yield takeLatest(mutePlayer.type, mutePlayerSaga);
  yield takeLatest(unmutePlayer.type, unmutePlayerSaga);
  yield takeLatest(resetPlayerAudioFix.type, resetPlayerAudioFixSaga);
  yield takeLatest(playSingleAudioFile.type, playSingleAudioFileSaga);
  yield takeLatest(stopSingleAudioFile.type, stopSingleAudioFileSaga);
  yield takeLatest(playMultipleAudioFiles.type, playMultipleAudioFilesSaga);
  yield takeLatest(
    pausePlayMultipleAudioFiles.type,
    pausePlayMultipleAudioFilesSaga
  );
  yield takeLatest(pausePlaySingleAudioFile.type, pausePlaySingleAudioFileSaga);
  yield takeLatest(seekSingleAudioFile.type, seekSingleAudioFileSaga);
  yield takeLatest(leaveRoom.type, leaveSaga);
}
